import { StandardMaterial, Color3, IDisposable, MeshBuilder, PhysicsAggregate, PhysicsShapeType, Scene, Vector3, Mesh, PhysicsBody } from '@babylonjs/core';
import { assertNonNull } from '@golf-ar/core';

import { VISIBILITY_OF_AUXILIARY_MESHES } from './scene';

/** Golf ball collider diameter. */
export const GOLF_BALL_COLLIDER_DIAMETER = 2.25;

/** Golf ball radius. */
export const GOLF_BALL_RADIUS = 0.075;

/** Golf ball. */
export class GolfBall implements IDisposable {

	private instance: Mesh | null = null;

	private instanceColliderForTrail: Mesh | null = null;

	private physicsAggregate: PhysicsAggregate | null = null;

	/** Position of the ball before the impact. */
	private lastBallPositionBeforeImpact: Vector3 | null = null;

	private readonly resources: IDisposable[] = [];

	public constructor(
		private readonly scene: Scene,
	) { }

	/** Save the position of the ball before impact. */
	public set ballPositionBeforeImpact(position: Vector3) {
		this.lastBallPositionBeforeImpact = position.clone();
	}

	/**
	 * Sets ball position with Y adjustment.
	 * @param position New position.
	 * @param applyRadiusOffset If true, the ball radius will be added to the Y position.
	 */
	public setPosition(position: Vector3, applyRadiusOffset = false): void {
		this.instancePhysicsBody.setLinearVelocity(Vector3.Zero());
		this.shouldDisablePhysicsBodyStep = false;

		const yOffset = applyRadiusOffset ? GOLF_BALL_RADIUS : 0;
		const adjustedPosition = new Vector3(position.x, position.y + yOffset, position.z);
		this.instanceMesh.setAbsolutePosition(adjustedPosition);
		this.shouldDisablePhysicsBodyStep = true;
	}

	/** Golf ball's physics body. */
	public get instancePhysicsBody(): PhysicsBody {
		assertNonNull(this.instanceMesh.physicsBody);
		return this.instanceMesh.physicsBody;
	}

	/** Restores position of the ball after falling.*/
	public restorePositionAfterFall(): void {
		assertNonNull(this.lastBallPositionBeforeImpact);
		this.setPosition(this.lastBallPositionBeforeImpact);
	}

	/** Golf ball mesh. */
	public get instanceMesh(): Mesh {
		assertNonNull(this.instance);
		return this.instance;
	}

	/** Golf ball collider mesh for trail. */
	public get instanceColliderMeshForTrail(): Mesh {
		assertNonNull(this.instanceColliderForTrail);
		return this.instanceColliderForTrail;
	}

	/** Golf ball physics aggregate. */
	public get physicsAggregateInstance(): PhysicsAggregate {
		assertNonNull(this.physicsAggregate);
		return this.physicsAggregate;
	}

	/** Enables and disables pre-step that consists in updating physics body. */
	public set shouldDisablePhysicsBodyStep(isDisabled: boolean) {
		/** Before disabling pre-step, we need to wait for all values to change, for example, position, rotation, etc. */
		if (isDisabled) {
			this.scene.onAfterPhysicsObservable.addOnce(() => {
				assertNonNull(this.instancePhysicsBody);
				this.instancePhysicsBody.disablePreStep = isDisabled;
			});
			return;
		}
		assertNonNull(this.instancePhysicsBody);
		this.instancePhysicsBody.disablePreStep = isDisabled;
	}

	/**
	 * Creates ball mesh.
	 * @param position Starting position of the ball.
	 */
	public create(position: Vector3): void {
		this.instance = this.createMesh(position);

		// Two colliders have been implemented, one to increase the radius of the click on the ball, the second to create a trail.
		// We need a separate collider to create a trail,
		// because if we create a trail based on a ball or a collider that is a child of the ball,
		// the trail will look like a spiral when the ball has an angular velocity.
		this.instanceColliderForTrail = this.createColliderForTrail();
		this.createColliderForAction(position);

		this.scene.onAfterPhysicsObservable.add(() => {
			if (!this.instanceMesh.absolutePosition.equals(this.instanceColliderMeshForTrail.absolutePosition)) {
				this.instanceColliderMeshForTrail.setAbsolutePosition(this.instanceMesh.absolutePosition);
			}
		});
	}

	/** @inheritdoc */
	public dispose(): void {
		this.resources.forEach(resource => resource.dispose());
	}

	private createMesh(position: Vector3): Mesh {
		const ball = MeshBuilder.CreateSphere('GolfBall', { diameter: GOLF_BALL_RADIUS * 2, segments: 7 });
		this.setMaterial(ball);

		const { x, y, z } = position.clone();
		ball.setAbsolutePosition(new Vector3(x, y + GOLF_BALL_RADIUS, z));

		this.setPhysicsAggregate(ball);

		this.resources.push(ball);

		return ball;
	}

	private setPhysicsAggregate(ball: Mesh): void {
		this.physicsAggregate = new PhysicsAggregate(
			ball,
			PhysicsShapeType.SPHERE,
			{ mass: 2, friction: 1 },
			this.scene,
		);

		this.resources.push(this.physicsAggregate);

		const damping = 10;

		this.physicsAggregate.body.setLinearDamping(damping);
		this.physicsAggregate.body.setAngularDamping(damping);
		this.physicsAggregate.body.setCollisionCallbackEnabled(true);
	}

	private setMaterial(ball: Mesh): void {
		const material = new StandardMaterial('GolfBallMaterial');
		material.diffuseColor = new Color3(0.6, 0.6, 0.6);
		material.emissiveColor = new Color3(0.275, 0.275, 0.275);
		ball.material = material;

		this.resources.push(material);
	}

	private createColliderForTrail(): Mesh {
		const collider = MeshBuilder.CreateSphere('GolfBallColliderForTrail', { diameter: GOLF_BALL_RADIUS * 2, segments: 10 });
		collider.visibility = VISIBILITY_OF_AUXILIARY_MESHES;
		collider.isPickable = false;
		this.resources.push(collider);

		return collider;
	}

	// It is needed to increase the area for clicking on the ball.
	private createColliderForAction(position: Vector3): void {
		const collider = MeshBuilder.CreateSphere('GolfBallColliderForAction', { diameter: GOLF_BALL_COLLIDER_DIAMETER, segments: 10 });
		collider.visibility = VISIBILITY_OF_AUXILIARY_MESHES;
		const { x, y, z } = position.clone();
		collider.setAbsolutePosition(new Vector3(x, y + GOLF_BALL_RADIUS, z));
		collider.setParent(this.instance);

		this.resources.push(collider);
	}
}
