import { IDisposable } from '@babylonjs/core';
import { AdvancedDynamicTexture, TextBlock, Rectangle, TextWrapping, Control } from '@babylonjs/gui';

/** Error message manager. */
export class ErrorMessageManager implements IDisposable {

	private readonly resources: IDisposable[] = [];

	/**
	 * Displays error message.
	 * @param message Error message to display.
	 */
	public displayErrorMessage(message: string): void {
		const advancedTexture = AdvancedDynamicTexture.CreateFullscreenUI('errorMessage');
		this.resources.push(advancedTexture);

		const rectangle = new Rectangle();
		rectangle.thickness = 0;
		rectangle.width = '100%';
		rectangle.height = '100%';
		rectangle.adaptHeightToChildren = true;
		rectangle.top = '-25%';

		advancedTexture.addControl(rectangle);

		const textBlock = new TextBlock();
		textBlock.text = message;
		textBlock.color = 'white';
		textBlock.fontSize = '24px';
		textBlock.paddingLeft = '10%';
		textBlock.paddingRight = '10%';
		textBlock.resizeToFit = true;
		textBlock.textWrapping = TextWrapping.WordWrap;
		textBlock.textHorizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;

		rectangle.addControl(textBlock);
	}

	/** @inheritdoc */
	public dispose(): void {
		this.resources.forEach(resource => resource.dispose());
	}
}
