import { AbstractMesh, ActionManager, ExecuteCodeAction, IAction, IDisposable, Matrix, Nullable, Quaternion, Scene, Vector3 } from '@babylonjs/core';

import { assertNonNull } from '../utils';
import { GolfClubImpactSetting } from '../models';

import { GolfBallArrow } from './golf-ball-arrow';
import { MainCameraType } from './main-camera';
import { ImpactAngleIndicator } from './impact-angle-indicator';

const MAX_VECTOR_VALUE = 15;

/** Golf ball trajectory manager. */
export abstract class AbstractGolfBallTrajectoryManager implements IDisposable {

	/** Impact angle indicator. */
	protected readonly impactAngleIndicator: ImpactAngleIndicator;

	/** Pick scene point. */
	protected pickedPoint: Vector3 | null = null;

	/** Whether the ball is stopped. */
	protected isBallStopped = true;

	/** Whether trajectory creation is enabled. */
	protected isEnabled = false;

	/** Golf ball arrow. */
	protected readonly golfBallArrow: GolfBallArrow;

	/** Interface used to define action. */
	protected readonly action: IAction;

	/** Impulse for ball. */
	protected impulse: Vector3 | null = null;

	private readonly onCreateBallTrajectoryCallback: () => void;

	private isTrajectoryVisible = false;

	public constructor(
		protected readonly scene: Scene,
		protected readonly ballMesh: AbstractMesh,
		protected readonly camera: MainCameraType,
		private readonly arrowDiameter: number,
	) {
		this.onCreateBallTrajectoryCallback = this.createBallTrajectory.bind(this);
		this.golfBallArrow = new GolfBallArrow(this.camera, scene, ballMesh, this.arrowDiameter);
		this.impactAngleIndicator = new ImpactAngleIndicator(this.scene);
		this.action = new ExecuteCodeAction(
			ActionManager.OnPickDownTrigger,
			() => this.onPickDown(),
		);
		this.ballMesh.actionManager = this.createActionManager();

		this.scene.onPointerObservable.add(eventData => {
			const eventType = eventData.event.type;
			if (this.isBallStopped) {
				if ((eventType === 'mouseup' || eventType === 'pointerup') && this.isTrajectoryVisible) {
					this.onMouseUp();
				}
				if ((eventType === 'mousemove' || eventType === 'pointermove') && this.isTrajectoryVisible) {
					this.onMouseMove();
				}
			}
		});
	}

	/** @inheritdoc */
	public dispose(): void {
		this.golfBallArrow.dispose();
		this.impactAngleIndicator.dispose();
		this.unregisterCreateBallTrajectory();
	}

	private registerCreateBallTrajectory(): void {
		this.scene.registerAfterRender(this.onCreateBallTrajectoryCallback);
	}

	/** Unregisters the ball trajectory creation callback after each render to avoid duplicate registrations. */
	protected unregisterCreateBallTrajectory(): void {
		this.scene.unregisterAfterRender(this.onCreateBallTrajectoryCallback);
	}

	/**
	 * Sets a boolean indicating whether trajectory creation is enabled.
	 * @param value Defines the new enabled state.
	 */
	public setEnabled(value: boolean): void {
		this.isEnabled = value;
		if (!this.isEnabled) {
			assertNonNull(this.ballMesh.actionManager);
			this.ballMesh.actionManager.unregisterAction(this.action);
		}
	}

	/** Create action manager for the golf mesh. */
	private createActionManager(): ActionManager {
		const actionManager = new ActionManager(this.scene);
		actionManager.isRecursive = true;
		return actionManager;
	}

	/** Handles mouse move event of the scene. */
	private onMouseMove(): void {
		const pickScenePoint = this.pickScenePoint();
		if (pickScenePoint == null) {
			return;
		}
		this.pickedPoint = pickScenePoint;
	}

	/** Handles mouse up event of the scene. */
	protected abstract onMouseUp(): void;

	private onPickDown(): void {
		if (!this.isTrajectoryVisible && this.isBallStopped) {
			this.impactAngleIndicator.showIndicator(this.getImpactSetting().angle);
			this.registerCreateBallTrajectory();
			this.isTrajectoryVisible = true;
		}
	}

	/** Create the ball trajectory. */
	protected createBallTrajectory(): void {
		if (this.pickedPoint == null) {
			return;
		}
		this.impulse = this.calculateImpulse();
		this.golfBallArrow.createArrowFor(this.impulse);
	}

	/** Clean up function for hiding all lines. */
	protected hideLines(): void {
		this.golfBallArrow.hideArrow();
		this.unregisterCreateBallTrajectory();
		this.isTrajectoryVisible = false;
	}

	/**
	 * Generate impulse for physics body.
	 * @param point Point to which golf ball should be sent.
	 */
	protected generateImpulse(): void {
		assertNonNull(this.impulse);
		this.ballMesh.physicsBody?.setLinearVelocity(this.impulse);
		this.pickedPoint = null;
	}

	/** Calculate impulse to hit the ball. */
	protected calculateImpulse(): Vector3 {
		const golfBallPosition = this.ballMesh.getAbsolutePosition().clone();

		assertNonNull(this.pickedPoint);
		const xzPoint = this.getXZVector(this.pickedPoint);
		const xzBallPoint = this.getXZVector(golfBallPosition);

		const forward = this.getImpulseWithoutImpactAngle(xzPoint, xzBallPoint);

		const length = forward.length();
		forward.normalize();

		// First we compute right vector by applying 2d rotation matrix with 90 degrees.
		// Then we compute cross product of forward and right to get up vector.
		const x = forward.x * forward.y;
		const y = -forward.z * forward.z - forward.x * forward.x;
		const z = forward.y * forward.z;

		const upVector = new Vector3(x, y, z);
		const { impactAngle } = this.impactAngleIndicator;

		const rotation = Quaternion.FromLookDirectionLH(forward, upVector)
			.multiply(Quaternion.FromEulerAngles(impactAngle * Math.PI / 180, 0, 0));

		const matrix = rotation.toRotationMatrix(Matrix.Identity());

		return Vector3.TransformNormal(Vector3.Forward().scaleInPlace(length), matrix);
	}

	/**
	 * Reset y to simplify computations.
	 * @param vector Vector.
	 */
	private getXZVector(vector: Vector3): Vector3 {
		vector.y = 0;
		return vector;
	}

	/**
	 * Generate accurate values without impact angle for dimensions of scene.
	 * @param point Point to which ball will be sent.
	 * @param ballPosition Position of golf ball.
	 */
	private getImpulseWithoutImpactAngle(point: Vector3, ballPosition: Vector3): Vector3 {
		const direction = point.subtract(ballPosition);

		// It is necessary to remove the dependence of the impulse on the distance between the camera and the ball.
		const distanceDivisible = 42;
		const distance = distanceDivisible / this.camera.position.subtract(ballPosition).length();

		const length = Math.min(direction.length() * distance, MAX_VECTOR_VALUE);
		direction.normalize();
		return direction.scaleInPlace(length * this.getImpactSetting().forceMultiplier);
	}

	/** Finds a pick scene point. */
	protected pickScenePoint(): Nullable<Vector3> {
		const { pickedPoint } = this.scene.pick(
			this.scene.pointerX,
			this.scene.pointerY,
		);

		return pickedPoint;
	}

	/** Get impact setting. */
	protected abstract getImpactSetting(): GolfClubImpactSetting;

	/**
	 * Get absolute velocity value for.
	 * @param val Value.
	 */
	protected getAbsoluteVelocityValueFor(val: number): number {
		return Number(Math.abs(val).toFixed(1));
	}
}
