import { GolfClubType } from './golfer';

/** Golf club impact angle. */
export type GolfClubImpactAngle = {

	/** Min. */
	readonly min: number;

	/** Max. */
	readonly max: number;
};

/** Golf club impact setting. */
export type GolfClubImpactSetting = {

	/** Force multiplier. */
	readonly forceMultiplier: number;

	/** Impact angle. */
	readonly angle: GolfClubImpactAngle;
};

/**
 * Get golf club impact setting.
 * @param golfClub Golf club.
 */
export function getGolfClubImpactSetting(golfClub: GolfClubType): GolfClubImpactSetting {
	return mapGolfClubToImpactSetting[golfClub];
}

const mapGolfClubToImpactSetting: Readonly<Record<GolfClubType, GolfClubImpactSetting>> = {
	[GolfClubType.Driver]: {
		forceMultiplier: 2,
		angle: {
			min: 5,
			max: 20,
		},
	},
	[GolfClubType.Iron]: {
		forceMultiplier: 1.35,
		angle: {
			min: 10,
			max: 40,
		},
	},
	[GolfClubType.Putter]: {
		forceMultiplier: 0.65,
		angle: {
			min: 5,
			max: 15,
		},
	},
};
